<template>
  <v-card :height="285" flat>
    <v-skeleton-loader transition="fade-transition" type="article" v-if="loaderMetric" />
    <v-hover v-slot:default="{hover}" open-delay="200" v-else>
      <v-card-text>

        <!-- draggable handler -->
        <v-icon v-if="draggable && hover" class="vue-draggable-handle vue-draggable-handle--icon" color="secondary" size="30">mdi-drag-horizontal</v-icon>
        <!-- end draggable handler -->

        <!-- metric header -->
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="9">
            <span class="d-inline-block body-2 text--secondary font-weight-medium text-truncate" style="max-width:100%">{{$t(`metrics.${metric}`)}}</span>
          </v-col>
          <v-col class="text-right" v-if="hover">
            <v-tooltip color="bgSearch" max-width="280px" transition="scale-transition" top>
              <template v-slot:activator="{on, attrs}">
                <v-icon class="mt-n1" v-bind="attrs" v-on="on" small color="secondary">mdi-information</v-icon>
              </template>
              <span class="d-block px-3 py-2">{{(config.description || 'Sin descripción')}}</span>
            </v-tooltip>
            <v-btn class="ml-2 mt-n1" v-if="removable" @click="$emit('destroy', true)" icon x-small><v-icon color="secondary" size="20">mdi-delete-outline</v-icon></v-btn>
          </v-col>
        </v-row>
        <!-- end metric header -->

        <!-- metric representation -->
        <v-row align="center" no-gutters>
          <v-col class="my-2" v-if="config.type.graph.class === 'line'">
            <chart-line
              class="d-block"
              axe-type-y="number"
              :background-color="config.type.graph.background_color"
              :colors="config.type.graph.colors"
              :chart-data="dataset"
              :chart-id="settings.i || `${metric}_graph`"
              :custom-labels="[$t(`metrics.${metric}`)]"
              :height="220"
              :number-rounded="true"
              :show-axe-x="false"
              :show-draw-border-grid-lines-axe-y="true"
              :show-grid-lines-axe-y="true"
              :show-point="true"
              style="position:relative;"
            />
          </v-col>
          <v-col class="mb-5" v-if="config.type.graph.class === 'barStacked'">
            <chart-bar-stacked
              class="d-block mt-4 chartjs-final-dataset-as-compute"
              axe-type-y="number"
              :chart-data="dataset"
              :colors="config.type.graph.colors"
              :custom-labels="[$t(`metrics.${metric}`)]"
              :final-as-compute="true"
              :height="220"
              :labels="config.type.graph.labels"
              :number-rounded="true"
              :show-draw-border-grid-lines-axe-y="true"
              style="position:relative"
            />
          </v-col>
        </v-row>
        <!-- end metric representation -->

        <!-- resize -->
        <v-btn v-if="resizable && hover" @click="$emit('resize', true)" class="vue-draggable-resize px-0" color="primary" height="60" x-small>
          <v-icon class="ml-0" dark x-small tab>{{ settings.w === 12 ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
        <!-- end resize -->

      </v-card-text>
    </v-hover>
  </v-card>
</template>
<script>
import ChartLine from '@/components/charts/ChartLine'
import ChartBarStacked from '@/components/charts/ChartBarStacked'
import MetricViewMixin from '@/mixins/MetricViewMixin'

export default {
  props: {
    draggable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    interval: {
      type: String,
      // required: true,
      default: () => ('monthly')
    },
    metric: {
      type: String,
      required: true
    },
    removable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    resizable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mixins: [
    MetricViewMixin
  ],
  components: {
    ChartLine,
    ChartBarStacked
  },
  data: () => ({
    dataset: {},
    config: {}
  }),
  created () {
    this.retrieveMetric()
  },
  watch: {
    comparative () {
      this.retrieveMetric()
    },
    interval () {
      this.retrieveMetric()
    },
    metric () {
      this.retrieveMetric()
    }
  },
  methods: {
    retrieveMetric () {
      this.loaderMetric = true

      this.$store.dispatch('analytics/GET', {
        resource: `metrics/${this.metric}/graph`,
        query: {
          interval: this.interval
        }
      })
      .then((response) => {
        this.dataset = response.data
        this.config = this.getMetric(this.metric)
      })
      .finally(() => {
        this.loaderMetric = false
      })
    }
  }
}
</script>