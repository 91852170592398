<template>
  <v-card :height="285" flat>
    <v-skeleton-loader transition="fade-transition" type="article" v-if="loaderMetric" />
    <v-hover v-slot:default="{hover}" open-delay="200" v-else>
      <v-card-text class="px-0">

        <!-- draggable handler -->
        <v-icon v-if="draggable && hover" class="vue-draggable-handle vue-draggable-handle--icon" color="secondary" size="30">mdi-drag-horizontal</v-icon>
        <!-- end draggable handler -->

        <!-- metric header -->
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="pl-3" cols="9">
            <span class="d-inline-block body-2 text--secondary font-weight-medium text-truncate" style="max-width:100%">{{$t(`metrics.${metric}`)}}</span>
          </v-col>
          <v-col class="pr-3 text-right" v-if="hover">
            <v-tooltip color="bgSearch" max-width="280px" transition="scale-transition" top>
              <template v-slot:activator="{on, attrs}">
                <v-icon class="mt-n1" v-bind="attrs" v-on="on" small color="secondary">mdi-information</v-icon>
              </template>
              <span class="d-block px-3 py-2">{{(config.description || 'Sin descripción')}}</span>
            </v-tooltip>
            <v-btn class="ml-2 mt-n1" v-if="removable" @click="$emit('destroy', true)" icon x-small><v-icon color="secondary" size="20">mdi-delete-outline</v-icon></v-btn>
          </v-col>
        </v-row>
        <!-- end metric header -->

        <!-- <v-divider /> -->

        <!-- metric representation -->
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="12">
            <v-data-table :headers="config.type.table.headers" :items="dataset.datasets" mobile-breakpoint hide-default-footer hide-default-header disable-sort>
              <template v-slot:header="{props: {headers}}">
                <v-table-headers :headers="headers" />
              </template>
              <template v-slot:[`item.label`]="{item}">
                {{$t(`metrics.${item.label}`)}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end metric representation -->

        <!-- resize -->
        <v-btn v-if="resizable && hover" @click="$emit('resize', true)" class="vue-draggable-resize px-0" color="primary" height="60" x-small>
          <v-icon class="ml-0" dark x-small tab>{{ settings.w === 12 ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
        <!-- end resize -->

      </v-card-text>
    </v-hover>
  </v-card>
</template>
<script>
import VTableHeaders from '@/components/VTable/VTableHeaders'
import MetricViewMixin from '@/mixins/MetricViewMixin'

export default {
  props: {
    draggable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    metric: {
      type: String,
      required: true
    },
    removable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    resizable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mixins: [
    MetricViewMixin
  ],
  components: {
    VTableHeaders
  },
  data: () => ({
    dataset: {},
    config: {}
  }),
  created () {
    this.loaderMetric = true

    this.$store.dispatch('analytics/GET', {
      resource: `metrics/${this.metric}/table`
    })
    .then((response) => {
      this.dataset = response.data
      this.config = this.getMetric(this.metric)
    })
    .finally(() => {
      this.loaderMetric = false
    })
  }
}
</script>